.services .fas {
    font-size: 90px;
    color: #279fdb;
    text-align: center;
    padding: 50px;
}
.services .card-title{
    font-weight: bold;
    text-align: center;
    color: #33455b;
}
.services{
    background-color: #f0f0f0;
}
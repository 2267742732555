body {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

.custom-color {
  color: #279fdb;
}

.custom-color2 {
  color: #242424;
}


.custom-btn {
  background-color: #279fdb;
  color: #fff;
  border-radius: 21px;
  border: none;
  padding: 5px 16px;
  box-shadow: 0px 0px 7px -3px black;
}

.custom-btn2 {

  border-radius: 21px;
  padding: 5px 16px;
}

.custom-btn2:hover {
  padding: 5px 30px;
  transition: 0.6s ease;
}

.custom-btn3 {
  padding: 5px 16px;
}

.custom-bg {
  background-color: #33455b;
}

.custom-bg2 {
  background-color: #26384f;
}

.custom-bg3 {
  background-color: #f0f0f0;
}

hr {
  width: 50px;
  height: 2px;
  margin: 0 auto;
}

.custom-shadow {
  box-shadow: 1px 5px 20px rgb(35 40 65 / 13%);
}
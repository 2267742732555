
img
{
	vertical-align: middle;
}
.img-responsive
{
	display: block;
	height: auto;
	max-width: 100%;
}
.img-rounded
{
	border-radius: 3px;
}.main-timeline{
    font-family: 'Roboto', sans-serif;
    position: relative;
}
.main-timeline:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline .timeline{
    width: 60%;
    padding: 0 30px 0 0;
    margin: 20px 0;
    float: left;
}
.main-timeline .timeline-content{
    background-color: #279fdb;
    min-height: 133px;
    padding: 30px 95px 30px 130px;
    display: block;
    position: relative;
    z-index: 1;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-content:before{
    content: '';
    background: #fff;
    border-radius: 100px 20px 20px 100px;
    position: absolute;
    left: 70px;
    top: 10px;
    bottom: 10px;
    right: -30px;
    z-index: -1;
}
.main-timeline .timeline-year{
    color: #fff;
    font-size: 45px;
    font-weight: 600;
    line-height: 45px;
    transform: translateY(-50%) rotate(-90deg);
    position: absolute;
    left: -15px;
    top: 50%;
}
.main-timeline .timeline-icon {
    color: #fff;
    background-color: #33455b;
    font-size: 40px;
    text-align: center;
    line-height: 84px;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    transform: translateY(-50%);
    position: absolute;
    right: -20px;
    top: 50%;
}
.main-timeline .title{
    color: #536E79;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 7px 0;
}
.main-timeline .description{
    color: #817f7f;
    font-size: 15px;
    margin: 0;
}
.main-timeline .timeline:nth-child(even){
    padding: 0 0 0 30px;
    float: right;
}
.main-timeline .timeline:nth-child(even) .timeline-content{ padding: 30px 130px 30px 95px; }
.main-timeline .timeline:nth-child(even) .timeline-content:before{
    border-radius: 20px 100px 100px 20px;
    left: -30px;
    right: 70px;
}
.main-timeline .timeline:nth-child(even) .timeline-year {
    right: -32px;
    left: auto;
    font-size: 37px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
    right: auto;
    left: -20px;
}
.main-timeline .timeline:nth-child(4n+2) .timeline-icon{
background-color: #279fdb;
}

.main-timeline .timeline:nth-child(4n+2) .timeline-content{
    background-color: #33455b;
}
.main-timeline .timeline:nth-child(4n+2) .title{ color: #536E79; }
.main-timeline .timeline:nth-child(4n+3) .timeline-content,
.main-timeline .timeline:nth-child(4n+3) .timeline-icon{
    background-color: #FEA726;
}
.main-timeline .timeline:nth-child(4n+3) .title{ color: #FEA726; }
.main-timeline .timeline:nth-child(4n+4) .timeline-content,
.main-timeline .timeline:nth-child(4n+4) .timeline-icon{
    background-color: #01BFA5;
}
.main-timeline .timeline:nth-child(4n+4) .title{ color: #01BFA5; }
@media screen and (max-width:767px){
    .main-timeline .timeline,
    .main-timeline .timeline:nth-child(even){
        text-align: center;
        width: 100%;
        padding: 0 0 30px 0;
        margin: 0 0 30px;
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(even) .timeline-content{
        padding: 80px 25px;
    }
    .main-timeline .timeline-content:before,
    .main-timeline .timeline:nth-child(even) .timeline-content:before{
        border-radius: 50px 50px 20px 20px;
        bottom: -30px;
        top: 65px;
        left: 10px;
        right: 10px;
    }
    .main-timeline .timeline-year,
    .main-timeline .timeline:nth-child(even) .timeline-year{
        transform: translateX(-50%) translateY(0);
        top: 10px;
        left: 50%;
    }
    .main-timeline .timeline-icon,
    .main-timeline .timeline:nth-child(even) .timeline-icon{
        font-size: 50px;
        line-height: 85px;
        width: 85px;
        height: 85px;
        transform: translateX(-50%);
        top: auto;
        bottom: -20px;
        right: auto;
        left: 50%;
    }
}
@media screen and (max-width:576px){
    .main-timeline .title{ font-size: 18px; }
}
License Terms
.img-thumbnail
{
	background-color: #fff;
	border: 1px solid #ededf0;
	border-radius: 3px;
	display: inline-block;
	height: auto;
	line-height: 1.428571429;
	max-width: 100%;
	
	padding: 2px;
	transition: all .2s ease-in-out;
	
}
.img-circle
{
	border-radius: 50%;
}
.timeline-centered {
    position: relative;
    margin-bottom: 30px;
}

    .timeline-centered:before, .timeline-centered:after {
        content: " ";
        display: table;
    }

    .timeline-centered:after {
        clear: both;
    }

    .timeline-centered:before, .timeline-centered:after {
        content: " ";
        display: table;
    }

    .timeline-centered:after {
        clear: both;
    }

    .timeline-centered:before {
        content: '';
        position: absolute;
        display: block;
        width: 4px;
        background: #f5f5f6;
        left: 50%;
        top: 20px;
        bottom: 20px;
        margin-left: -4px;
    }

    .timeline-centered .timeline-entry {
        position: relative;
        width: 50%;
        float: right;
        margin-bottom: 70px;
        clear: both;
    }

        .timeline-centered .timeline-entry:before, .timeline-centered .timeline-entry:after {
            content: " ";
            display: table;
        }

        .timeline-centered .timeline-entry:after {
            clear: both;
        }

        .timeline-centered .timeline-entry:before, .timeline-centered .timeline-entry:after {
            content: " ";
            display: table;
        }

        .timeline-centered .timeline-entry:after {
            clear: both;
        }

        .timeline-centered .timeline-entry.begin {
            margin-bottom: 0;
        }

        .timeline-centered .timeline-entry.left-aligned {
            float: left;
        }

            .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
                margin-left: 0;
                margin-right: -18px;
            }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
                    left: auto;
                    right: -100px;
                    text-align: left;
                }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
                    float: right;
                }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
                    margin-left: 0;
                    margin-right: 70px;
                }

                    .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
                        left: auto;
                        right: 0;
                        margin-left: 0;
                        margin-right: -9px;
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

        .timeline-centered .timeline-entry .timeline-entry-inner {
            position: relative;
            margin-left: -22px;
        }

            .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
                content: " ";
                display: table;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner:after {
                clear: both;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
                content: " ";
                display: table;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner:after {
                clear: both;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
                position: absolute;
                left: -100px;
                text-align: right;
                padding: 10px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
                    display: block;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
                        font-size: 12px;
                    }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
                background: #fff;
                color: #737881;
                display: block;
                width: 40px;
                height: 40px;
                -webkit-background-clip: padding-box;
                -moz-background-clip: padding;
                background-clip: padding-box;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;
                text-align: center;
                -moz-box-shadow: 0 0 0 5px #f5f5f6;
                -webkit-box-shadow: 0 0 0 5px #f5f5f6;
                box-shadow: 0 0 0 5px #f5f5f6;
                line-height: 40px;
                font-size: 15px;
                float: left;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
                    background-color: #303641;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-secondary {
                    background-color: #ee4749;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
                    background-color: #00a651;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
                    background-color: #21a9e1;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
                    background-color: #fad839;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
                    background-color: #cc2424;
                    color: #fff;
                }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
                position: relative;
                background: #f5f5f6;
                padding: 1.7em;
                margin-left: 70px;
                -webkit-background-clip: padding-box;
                -moz-background-clip: padding;
                background-clip: padding-box;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9px 9px 9px 0;
                    border-color: transparent #f5f5f6 transparent transparent;
                    left: 0;
                    top: 10px;
                    margin-left: -9px;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2, .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
                    color: #737881;
                    font-family: "Noto Sans",sans-serif;
                    font-size: 12px;
                    margin: 0;
                    line-height: 1.428571429;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
                        margin-top: 15px;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
                        color: #303641;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
                        -webkit-opacity: .6;
                        -moz-opacity: .6;
                        opacity: .6;
                        -ms-filter: alpha(opacity=60);
                        filter: alpha(opacity=60);
                    }

.nav-link a, Link{
    text-decoration: none;
    color: #33455b;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.navbar{
   box-shadow: 1px 1px 20px rgb(35 40 65 / 13%);
   
}
.bg-myBg{
    background-color: #fff;;
    height: 90px;
}
.title-text{
    color: #279fdb;
}
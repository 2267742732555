#clients .client-logo {
    padding: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 10px solid #f0f0f0;
    border-bottom: 10px solid #f0f0f0;
    overflow: hidden;
    background: #fff;
    height: 180px;
}
#clients .clients-wrap {
    /* border-top: 1px solid #d6eaff;
    border-left: 1px solid #d6eaff; */
    margin-bottom: 30px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
.client{
    background-color: #f0f0f0;
}
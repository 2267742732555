.progress {
    height: 10px;
    border-radius: 1rem;
    transition: 1s ease;
    transition-delay: 0.5s;
  }
  
.progress-div {
    background-color: rgb(233, 233, 233);
    border-radius: .5rem;
}